import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class IndirectTax extends Component {
    render() {
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Indirect Tax Accounting Services</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/tax-services">Tax Services</Link></li> <li>Indirect Tax Accounting Services</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Indirect Tax Accounting Services</h1>
              </div>
              <p> Indirect taxes are those taxes that get levied by the central and state government on the services, consumer rights, and privileges but not only on the property or income. It consists of the constructors tariff on the customs tariff along with the special tax paid on production and the value-added tax related to the certain stages of production and distribution of products. It is all-inclusive of the relation of indirect taxes because all of them are not directly applicable to the consumer's income.</p>
              <h3 className="title">The Specialty We Hold</h3>
              <p> We hold the consideration of the indirect taxes that are less when compared to the Income-Tax. Certain state Agencies always increase the taxes for the higher income generation for consideration of this accountancy. You will require reliable professionals to work by your side. When you are outsourcing the work to us, you can get the results in the needed time. That said, we can always consider the complex and long supply chain for both the medium to large Enterprise that will ensure consideration of the indirect taxes' compliance and management.</p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/Indirect-tax.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <p>We have the professionals for indirect tax management to have in-depth knowledge related to the various laws and regulations related to each state. </p>
            <p>We always strive to make a supply chain tax efficient. We can give the complete service and Management related to the special valuation registration. That said, we can work with the customs department, summary statement, and related services. </p>
            <p>We can help in drafting the collaboration agreement, joint venture agreement, technical assistance agreement, and others. We can assist in the preparation of the reply to the questionnaire. Besides, we can also work to assist in obtaining the renewal of the order fee. We can work in terms of consultant and governance automation and sensitization of the tax processes. We can use tax translation services, as well as sales and use tax compliance.  We can work with the data and analytics, unclaimed property services, complaints, and Consulting Services.</p>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default IndirectTax