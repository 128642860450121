import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class TaxSolutions extends Component {
    render() {
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Tax Solutions</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/tax-services">Tax Services</Link></li> <li>Tax Solutions</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Tax Solutions (Including Complete Tax Solutions)</h1>
              </div> 
              <p>We are a team of experienced and efficient tax preparation professionals who can give the best approach for tax solutions accounting. Whether you are looking for assistance with the personal or business income tax file, we understand that each financial situation is unique. That said, we will be actively working at tax time and throughout the year. We have a team of the most amazing individuals and commercial enterprises completely dependent on Tax Solutions &amp; Accounting Services.</p>
              <p>We can always work to provide experienced and reliable income tax preparation. Besides, we also go ahead with the tax filing, as well as other financial services designed specifically for optimization of the financial security and providing peace of mind. With us, you can get the assistance of limiting tax liability and maximizing business and personal tax savings. </p> 
              <p>We can give the most effective approach to Tax Solutions &amp; Accounting Services. The wonderful part is that we are a dedicated team of qualified tax specialists who can actively work to provide professional expertise and a wide range of tax and accounting strategies. We will see that all our services are tailored to your particular financial needs. </p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/Tax-Solutions.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <h3 className="title">What Makes Us Special : </h3>
            <ul className="ser-list">
              <li>Accurate and prompt personal and commercial tax preparation alongside the Bookkeeping services and system set-up.</li>
              <li>Availability of assistance for the Small or medium-sized business. We can also deal with tax management and payroll services.</li>
              <li>Tax planning, Rectification of unfiled tax returns, IRS audit representation, and Back taxes owed.</li>
              <li>Availability of Quick cash refunds.</li>
            </ul>
            <p>We promise to work with the idea of integrating highly skilled tax preparation. We can do so with reliable and personalized service; we can offer first-class Tax Solutions &amp; Accounting Services that will help with the achievement of our client’s financial goals. </p>
            <p>The best part of our assistance is that we can work with the impersonal service. That said, we can ensure assistance with the financial solution for your business. We always hold the commitment to provide accounting, tax, payroll, and business consulting services. </p>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default TaxSolutions