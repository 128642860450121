import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class FringeBenefitsTax extends Component {
    render() {
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Fringe Benefits Tax</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/tax-services">Tax Services</Link></li> <li>Fringe Benefits Tax</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Fringe Benefits Tax (FBT)</h1>
              </div>
              <p>Certain rules come associated with fringe benefits. Tax changes occur regularly. For a company keeping up with all these changes and noting minute changes always becomes feasible for the long-term objectives. The quality accounting bookkeepers can keep up to date with the tax advice, thus enabling the providing of accurate advice on fringe benefits tax. They can also ensure taking up quality assurance for keeping up with the consequences of the fringe benefits tax. </p>
              <h3 className="title">Assistance With a Capable Team</h3>
              <p>Our experts can help in the development of the complete cost-effective employee benefit plan as well. Our objective is to put everything in place and ensure that the required records are maintained about the fringe benefits that will ensure giving the records after a certain time. It can keep account of the certain benefits that will be providing employees or employee’s families or other Associates many benefits. </p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/Fringe-Benefits-Tax.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <p>The accounting professionals from our team will ensure a dedicated approach in the management of the same. If you are into a mid-sized business or a large corporation, then these industry experts can work with the business advisory taxation and accounting. </p>
            <p>We will always work hand-in-hand with you for the management of the Assets and structuring of the business for asset protection. You can also get a beveled edge for your business, which can take you steadily to the top. With our business tax accounting firm, you can discuss getting the work done in the needed time. </p>
            <p>So with the Outsourcing to our team, you can keep away challenges from harming you. There won't be any problems of infrequency or irregularity in considering the work. Our team always ensures keeping the burden of income tax accounting off your shoulders and will ensure giving you a clear explanation of what the finance team needs to know. Our plans also come inclusive of the income tax accounting and disclosure.</p>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default FringeBenefitsTax