import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class PerformanceImprovement extends Component {
    render() {
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Performance Improvement </h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/business-advisory">Business Advisory</Link></li> <li>Performance Improvement </li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Performance Improvement </h1>
              </div> 
              <p>Both the debtors and the creditors are usually in a situation that comes with the enforcement of the insolvency and always seek guidance from independent financial advisers like us. We can work with the companies in the efforts to maximize the recovery for the creditors. We specialize in terms of extensive experience and providing advisory services to businesses. Our services can also come inclusive of the creditor committee, debtors, and the trustees. We always work prepared to provide assistance in the direction of the commission and the Council.</p>
              <p>In work, we ensure the evaluation of the solutions that are alternative. Whether it is the out-of-court restructuring of the formal bankruptcy proceeding, we help with the determination of the economic feasibility of the restructuring, alternative for the prospects.</p>
              <p>It can also come inclusive of the operations of the steady, organized plan while getting access. The strength of the existing management personnel may come from the consultation for confirming the disclosure statement and their liquidating trust.</p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/performance-improvement.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <h1 className="title">The Scope of Services</h1>
            <p>We can give the optional services that work for the analysis of the operations, preparation of the cash flow budgets, access point of the business viability, preparation of the receivership budget, and so on.</p>
            <h3 className="title">	Accounting and Reporting</h3>
            In this, we can ensure your preparing reports of the discrepancies or other inaccuracies, performance of the accounting services, and communication with the lender.<p />
            <h3 className="title">	Litigation Support</h3>
            <p>We can give the review of The Prepetition activities, litigation support, and expert testimony if required.</p>
            <h3 className="title">	Assessment of The Organization</h3>
            <p>We can plan and organize with the analysis and review of the plan and evaluation of the alternative. Besides that, we also hold the liquidation analysis required for the planned procedure. We can develop the liquidation analysis as well.</p><br />
            <p>If you require any assistance in terms of the above-mentioned services, book our team services today. We will be happy to assist you.</p>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default PerformanceImprovement