
import PropTypes from "prop-types";
import React from "react";
import latestBlogData from "../data/latest-post.json";
import LatestBlogSingle from "../components/pages/single/LatestBlogSingle";

const LatestBlog = () => {
  return (
    <section className="flat-row v6">
    <div className="container">
      <div className="row">
        <div className="col-md-12">  
          <div className="title-section text-center">
            <h1 className="title">Latest Blog</h1>
          </div>         
        </div>
      </div>
      <div className="blog-carosuel-wrap">
        <div className="blog-shortcode post-grid">
        {/* SLIDE 1 */}
          {latestBlogData &&
            latestBlogData.map((single, key) => {
              return (
                <LatestBlogSingle
                  data={single}
                  key={key}
                  spaceBottomClass="mb-30"
                />
              );
            })}
            </div>
            </div>
          </div>  
        </section>
  );
};

LatestBlog.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default LatestBlog;
