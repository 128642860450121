import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class StrategicPlanning extends Component {
    render() {
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Strategic Planning</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/business-advisory">Business Advisory</Link></li> <li>Strategic Planning </li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Strategic Planning</h1>
              </div>
              <p>Small businesses come in the owner-managed form and oftentimes hold constraints that will be challenging to statistically plan for the future growth of the company. That said we always involve our team members for considering the real added value. We have a thorough understanding of the business finances and can also offer the plans with strategic services that will be mapping out the clear direction for the business we can set the high-end goals with our efforts. </p>
              <p>With years of experience, we have helped numerous clients plan for the future. That said, we always pick the strategy of understanding and identification of what you are currently doing and the key points of the development of the strategic action plan. </p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/Strategic-planning.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <h1 className="title">The assistance that we provide in the field</h1>
            <p>We can ensure that our efforts can ensure everything will be remaining on track. Whether you are looking for expansion, release of the capital or planning an exit. We can give the strategy service that will be helping in consideration of the options and formulation of the written plan.</p>
            <p>For leading businesses, strategic planning becomes problematic. To make the journey safe and predictable, we can consider the plans with the business experiences that will be helping create the roadmap towards the goals. We have a team of experts who will also be assisting and streamlining procedures. We do so by providing strategic planning services.</p>
            <p>Planning services that we provide alongside the budgeting management and the annual financial forecast comes with the involvement of the recognition of the gaps and identification of the opportunities, key performance indicators that are inclusive of the cost-profit revenue versus target. </p>
            <h1 className="title">The involvement of benchmarking principles </h1>
            <p>The systems and the processes development also comes inclusive of the analysis of the existing processes, recommended quantifiable changes, technologies preferred and involved in the same industry standard. Benchmarking comes with the availability of comparable processes and performance metrics. We can also take the actionable steps.</p>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default StrategicPlanning