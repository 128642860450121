import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const OurClientsSingle = ({ data }) => {
  return (
    <div className="client"><img src={process.env.PUBLIC_URL + data.client_logo} alt="clients_image" /></div>
  );
};

OurClientsSingle.propTypes = {
  data: PropTypes.object,
  spaceBottomClass: PropTypes.string
};

export default OurClientsSingle;
