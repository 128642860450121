import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const MeetOurTeamSingle = ({ data }) => {
  return (
    <div className="col-lg-3 col-sm-6">
    <div className="flat-team style2">                            
      <div className="avatar">             
        <img src={process.env.PUBLIC_URL + data.avatar} alt="team" />
      </div>                        
      <div className="content text-center">
        <div className="info-team">
          <h5 className="name"> { data.name } </h5>
          <p className="position">{ data.position }</p>
        </div>
        <ul className="social-links style3 team">
          <li><a href={ data.facebook }><i className="fa fa-facebook" /></a></li>
          <li><a href={ data.twitter }><i className="fa fa-twitter" /></a></li>
          <li><a href={ data.linkedin }><i className="fa fa-linkedin" /></a></li>
        </ul>
      </div>
    </div>
  </div>
  );
};

MeetOurTeamSingle.propTypes = {
  data: PropTypes.object,
  spaceBottomClass: PropTypes.string
};

export default MeetOurTeamSingle;
