import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class StrategicTaxPlanning extends Component {
    render() {
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Strategic Tax Planning</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/tax-services">Tax Services</Link></li> <li>Strategic Tax Planning</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Strategic Tax Planning Services</h1>
              </div> 
              <p>We are a team of proactive tax planners who always hold the certification in our work. We work as the high end Certified Tax Planners who always stick to the recent tax law changes. We can identify the points that can affect all business owners. That said, we can provide strategic planning for putting control on every aspect. This is the proper planning that can help to maximize the benefits you are entitled to. </p>
              <p>The focus we hold in work is to help our clients pay the least amount of tax that is legally feasible. The best part is that we strive to understand your business and personal situation. Based on this determination, we use tax strategies to find all legal loopholes. We only hold the points that apply to your situation.</p>
              <p>With us, you can rest assured of taking advantage of our complimentary second opinion that can work favorably well for the income tax return to minimize income taxes. We will favor the aspect of growing the value of your business.</p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/Strategic-Tax-Planning.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <p> You can join us today to get access to the needed assistance. All you have to do is to just get in touch. For availing of our services, we can arrange a free consultation. With our expert team, you can get an explanation of the support available to you. We can also work as business advisors to help you grow your business. The best part of our service is that with us you can get a fixed quote. We always make sure to keep our fees fixed and tailored specifically to your needs. The approach that makes us the best</p>
            <p> We can give an approach that is Affordable and cost-effective. We will make sure that our business ideas are designed to suit all sizes of business operations. Besides, we also excel in keeping professional services costs affordable. You can get better and fixed monthly charges in an annual contract with us. We always do so based on the number of expected monthly transaction volume.</p>
            <p> At work, we can guarantee using easily understandable accounting software. We can also support you with the preparation of the submission documents required for meeting the government requirements relating to the programs.</p>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default StrategicTaxPlanning