import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class ComplianceTax extends Component {
    render() {
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Tax Compliance</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/tax-services">Tax Services</Link></li> <li>Tax Compliance</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Tax Compliance</h1>
              </div>
              <p>We understand that compliance with local accounting legislation, standards, and reporting timeliness becomes a vital requirement for expanding into new territory. That said, we can always hold the service for complying with the speed of change accounting and associated tax laws. We will involve our team always to assist you in staying updated about the changes both to the laws and application in tax filing and compliance procedures.</p>
              <p> Moreover, we understand that sometimes the uncertainties surrounding tax laws' interpretation may lead to troublesome business points. We also stay tuned to the increase in disputes and formulate ways to resolve them. The general rule is that the increasing sophistication and expertise lying in the tax authority means that the taxpayer needs support and advice at all stages. </p>
              <p> To keep away additional issues related to the tax compliance life cycle, we can involve our team to stay dedicated to delivering the tax compliance services. We can also work-related tax consulting services. The best part is that we will strive to meet the higher standards required by major multinational groups in work. We always involve our team to serve with the best approach. </p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/Compliance-Tax.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <p> We can work with qualified and internationally experienced tax specialists. Besides, we can also involve the certified public and tax accountants (CPA &amp; CPTA) in work. In addition to that, we will involve compliance with tax laws and reporting requirements. We do so against ever-changing global standards. </p>
            <p> Our service's best part is working with a team consisting of tax compliance and tax consulting specialists. We always involve the knowledge of your business and specific compliance expertise. We always involve the same into one team. We always pay attention to working with the tax authority, which also holds in-depth insight into the tax authorities' workings. We can do so by adding significant value to your business.</p>
          </div>
          <div className="paratext2 page-pd-5rem">
            <h3 className="title">How do we assist in the work?</h3>
            <ul className="ser-list">
              <li>Analysis of Corporate Income Tax compliance services</li>
              <li>Availability of Value added Tax compliance services.</li>
              <li>Withholding Tax compliance services along with the involvement of Payroll tax services for entities</li>
              <li>Personal Income Tax compliance services and Compliance for other taxes (In the work, we also look after the needs of accounting for mineral royalty tax, immovable property tax, etc.)</li>
              <li>Registration/deregistration with the involvement of the state authorities for different taxes</li>
              <li>Tax training for your personnel</li>
            </ul>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default ComplianceTax