
import PropTypes from "prop-types";
import React from "react";
import teamData from "../data/our-team.json";
import MeetOurTeamSingle from "../components/pages/single/MeetOurTeamSingle";

const MeetOurTeam = () => {
  return (
    <section className="flat-row  page-teammember2 padding2 bg-section">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="title-section text-center">
            <h1 className="title">Meet Our Team</h1>
            <div className="sub-title">
              We are here to Acelerate your business and help you find the way.
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {/* SLIDE 1 */}
          {teamData &&
            teamData.map((single, key) => {
              return (
                <MeetOurTeamSingle
                  data={single}
                  key={key}
                  spaceBottomClass="mb-30"
                />
              );
            })}
            </div>
        </div>
      </section>
  );
};

MeetOurTeam.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default MeetOurTeam;
