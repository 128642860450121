import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class BusinessBuyingSelling extends Component {
    render() {
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Business Buying and Selling</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/business-advisory">Business Advisory</Link></li> <li>Business Buying and Selling</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Business Buying and Selling</h1>
              </div>
              <p>
                We understand that buying and selling of a business come with the complexity. That said, we can always give the approach that will also ensure consideration of these complexities with the utmost professionalism. We also work for aiming to discuss with you everything regarding the procedure. We can do so with asset purchase/sale or a share purchase/sale. The best part of our services is that we are on hand to assist you always with plenty of matters related to the above. If you want to get further information about our work, contact us.
              </p>
              <p>We can provide you with the business buying &amp; selling services that will be giving you the result you're looking for. Besides, you can also get due diligence services, business exit strategy planning, and business valuations. Our professionals can make an important financial decision for you without further hindrances. We understand that plenty of things that fall under consideration with business buying and selling. You can also get the protocols available for management to get the outcome you want. At no circumstance, we will make the process emotionally draining and time-consuming.</p>
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/Business-Buying-and-selling.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <p>We are proactive professionals who use a structured approach to achieve a better result. We put the focus on details rather than simply reacting to the situation. We can also give you the amazing expert advice to really make a difference. We can also ensure the utilization of tailored advice from business experts. Overall, you can rest assured about getting business valuations, exit strategy planning, due diligence services, sale preparation and business buying services.</p>
            <p>The best part is that during purchasing or selling, we consider preparation and planning. Professional approach with the professional team, experience and expertise, can ensure creation of success. We are the licensed public accounting firm, who also have the team of the chartered professional accountants to give accounting and assurance, to tax consulting and business advisory. </p>
            <p>We consider the business priorities: the specific components of the buy/sell; business assessment and valuation; cash flow position; tax minimization strategies; and other such norms. 
              So, you can rest assured that we will work hand-in-hand with each client to ensure flexible outcomes while providing a stress-free process.
            </p>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default BusinessBuyingSelling