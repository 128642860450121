import React,  { Component, Fragment } from 'react';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import TaxServices from '../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class TaxService extends Component {
    render() {
        return (
           <Fragment>
            <Header />
                      {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Tax services</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li>Tax services</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-section style3 left">
                <h1 className="title">Tax services</h1>
              </div>
            </div>
            <div className="col-12 paratext">
              <p>
                With us, you can rest assured about getting the tax accounting service because we will help in dealing with the tax-related accounting while also filling in the income tax. We can also work with taxes based on small business practices. We can work to engage with the service providers for improved accuracy and timely preparation of the tax accounting balance.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="iconbox style3 services-more box-shadow2 iconTax">
                <div className="d-flex align-items-center customServices">
                  <div className="p-2 flex-grow-1">
                    <div className="box-content">
                      <h5 className="box-title">Compliance Tax </h5>  
                      <p>You will get the availability of the service tax compliance services from well-vetted professionals.</p> 
                    </div>
                  </div>
                  <div className="p-2 align-self-center">
                    <Link className="readmore" to="/services/tax-service/compliance-tax">Read More <i className="fa fa-angle-right" /></Link>
                  </div>
                </div>
              </div>     
            </div>
            <div className="col-lg-6">
              <div className="iconbox style3 services-more box-shadow2 iconTax">
                <div className="d-flex align-items-center customServices">
                  <div className="p-2 flex-grow-1">
                    <div className="box-content">
                      <h5 className="box-title">Companies, Trust Structures, and Joint Ventures</h5>  
                      <p>We play a significant role in the dynamic business environment that is providing responsible Tax Planning as well as structuring alternatives for meeting business goals and objectives.</p> 
                    </div>
                  </div>
                  <div className="p-2 align-self-center">
                    <a className="readmore" href="companies-trust-structures-and-joint-ventures.html">Read More <i className="fa fa-angle-right" /></a>
                  </div>
                </div>
              </div>     
            </div>
            <div className="col-lg-6">
              <div className="iconbox style3 services-more box-shadow2 iconTax">
                <div className="d-flex align-items-center customServices">
                  <div className="p-2 flex-grow-1">
                    <div className="box-content">
                      <h5 className="box-title">Capital Gains Tax (CGT)</h5>  
                      <p>We understand the requirement of Careful planning of capital asset disposals. In this regard, our professionals would be happy to discuss the options with you</p> 
                    </div>
                  </div>
                  <div className="p-2 align-self-center">
                    <Link className="readmore" to="/services/tax-service/capital-gains-tax">Read More <i className="fa fa-angle-right" /></Link>
                  </div>
                </div>
              </div>     
            </div>
            <div className="col-lg-6">
              <div className="iconbox style3 services-more box-shadow2 iconTax">
                <div className="d-flex align-items-center customServices">
                  <div className="p-2 flex-grow-1">
                    <div className="box-content">
                      <h5 className="box-title">Goods and Services Tax (GST) </h5>  
                      <p>We can provide you with efficient goods and services tax accounting support to help in invoicing and accounting of the applicable GST invoice.</p> 
                    </div>
                  </div>
                  <div className="p-2 align-self-center">
                    <Link className="readmore" to="/services/tax-service/goods-and-services-tax">Read More <i className="fa fa-angle-right" /></Link>
                  </div>
                </div>
              </div>     
            </div>
            <div className="col-lg-6">
              <div className="iconbox style3 services-more box-shadow2 iconTax">
                <div className="d-flex align-items-center customServices">
                  <div className="p-2 flex-grow-1">
                    <div className="box-content">
                      <h5 className="box-title">Fringe Benefits Tax (FBT)</h5>  
                      <p>We are the accounting specialist’s team who will always be giving you practical experience in terms of accounting taxation and finance related to the Fringe tax.</p> 
                    </div>
                  </div>
                  <div className="p-2 align-self-center">
                    <Link className="readmore" to="/services/tax-service/fringe-benefits-tax">Read More <i className="fa fa-angle-right" /></Link>
                  </div>
                </div>
              </div>      
            </div>
            <div className="col-lg-6">
              <div className="iconbox style3 services-more box-shadow2 iconTax">
                <div className="d-flex align-items-center customServices">
                  <div className="p-2 flex-grow-1">
                    <div className="box-content">
                      <h5 className="box-title">Income Tax Return Preparation</h5>  
                      <p>We have operated Income Tax preparation services. We will be preparing the income tax invoice in the offices or also anywhere.</p> 
                    </div>
                  </div>
                  <div className="p-2 align-self-center">
                    <Link className="readmore" to="/services/tax-service/income-tax-return-preparation">Read More <i className="fa fa-angle-right" /></Link>
                  </div>
                </div>
              </div>     
            </div>
            <div className="col-lg-6">
              <div className="iconbox style3 services-more box-shadow2 iconTax">
                <div className="d-flex align-items-center customServices">
                  <div className="p-2 flex-grow-1">
                    <div className="box-content">
                      <h5 className="box-title">Indirect Tax</h5>  
                      <p>The indirect tax service becomes a managed service that can help build the tax technologies from the ground up or support existing tax with the systems resources.</p> 
                    </div>
                  </div>
                  <div className="p-2 align-self-center">
                    <Link className="readmore" to="/services/tax-service/indirect-tax">Read More <i className="fa fa-angle-right" /></Link>
                  </div>
                </div>
              </div>     
            </div>
            <div className="col-lg-6">
              <div className="iconbox style3 services-more box-shadow2 iconTax">
                <div className="d-flex align-items-center customServices">
                  <div className="p-2 flex-grow-1">
                    <div className="box-content">
                      <h5 className="box-title">R&amp;D Tax and Government Incentives</h5>  
                      <p>We have an excellent team of our professionals who will be bringing the extensive industry knowledge and the diverse technical background.</p> 
                    </div>
                  </div>
                  <div className="p-2 align-self-center">
                    <Link className="readmore" to="/services/tax-service/r&d-tax-and-government-incentives">Read More <i className="fa fa-angle-right" /></Link>
                  </div>
                </div>
              </div> 
            </div>
            <div className="col-lg-6">
              <div className="iconbox style3 services-more box-shadow2 iconTax">
                <div className="d-flex align-items-center customServices">
                  <div className="p-2 flex-grow-1">
                    <div className="box-content">
                      <h5 className="box-title">Strategic Tax Planning </h5>  
                      <p>The strategic tax and accounting become a part of the CPA firm focused on the small business owners and the individuals.</p> 
                    </div>
                  </div>
                  <div className="p-2 align-self-center">
                    <Link className="readmore" to="/services/tax-service/strategic-tax-planning">Read More <i className="fa fa-angle-right" /></Link>
                  </div>
                </div>
              </div>     
            </div>
            <div className="col-lg-6">
              <div className="iconbox style3 services-more box-shadow2 iconTax">
                <div className="d-flex align-items-center customServices">
                  <div className="p-2 flex-grow-1">
                    <div className="box-content">
                      <h5 className="box-title">Tax Solutions (including Complete Tax Solutions)</h5>  
                      <p>We can provide you with high-end tax solutions and accounting services because our well-vetted professionals will give you solutions with expertise</p> 
                    </div>
                  </div>
                  <div className="p-2 align-self-center">
                    <Link className="readmore" to="/services/tax-service/tax-solutions">Read More <i className="fa fa-angle-right" /></Link>
                  </div>
                </div>
              </div>     
            </div>
            <div className="col-lg-6">
              <div className="iconbox style3 services-more box-shadow2 iconTax">
                <div className="d-flex align-items-center customServices">
                  <div className="p-2 flex-grow-1">
                    <div className="box-content">
                      <h5 className="box-title">Transfer Pricing</h5>  
                      <p>We understand how companies are always looking for ways to increase efficiency and keep everything competitive and profitable.</p> 
                    </div>
                  </div>
                  <div className="p-2 align-self-center">
                    <Link className="readmore" to="/services/tax-service/transfer-pricing">Read More <i className="fa fa-angle-right" /></Link>
                  </div>
                </div>
              </div>     
            </div>
            <div className="col-lg-6">
              <div className="iconbox style3 services-more box-shadow2 iconTax">
                <div className="d-flex align-items-center customServices">
                  <div className="p-2 flex-grow-1">
                    <div className="box-content">
                      <h5 className="box-title">Tax Audit Preparations and Advisory                              </h5>  
                      <p>We have a proper understanding of how audits are increasing in terms of complexity. That said, for making your task less straining</p> 
                    </div>
                  </div>
                  <div className="p-2 align-self-center">
                    <Link className="readmore" to="/services/tax-service/tax-audit-preparations-and-advisory">Read More <i className="fa fa-angle-right" /></Link>
                  </div>
                </div>
              </div>     
            </div>
            <div className="col-lg-12 col-12 paratext">
              <p>By now, you have an idea about how particular our team of experts is in serving you with many tax-related services. We always do everything with our skill set and ensure no more issues in the overall procedure.</p>
            </div>
          </div>  
        </div>
      </section>
       
             <Footer/>
           </Fragment>
        )
    }
}

export default TaxService