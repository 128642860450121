import React,  { Fragment } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import AboutBanner from '../../assets/images/parallax/about-us.jpg';
import {Link} from "react-router-dom";
const Contact = () => {
  return (
      <>
          <Header/>
                           {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${AboutBanner})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Contact Us</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li>Contact Us</li>             
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
         <section className="abutus">
          <div className="container">
             <div className='row'>
                 <div className="col-lg-6 col-md-6 col-12">
                 <h2>MELBOURNE</h2>
                 <ul className="flat-information">
                    <li><strong>DANDENONG</strong><br/> SMS Accounting Partners</li>
                    <li><i className="fa fa-map-marker" /><a href="#">4 langhorne street Dandenong Vic 3175
                      </a></li>
                    <li><i className="fa fa-phone" /><a href="#"> (03) 95309750</a></li>
                    <li><i className="fa fa-envelope" /><a href="#">info@smstax.com.au</a></li>
                  </ul>   
                 </div>
                 <div className="col-lg-6 col-md-6 col-12">
                 <iframe loading="lazy" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2223.541707512252!2d145.21502998375342!3d-37.9873302769036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6140672b07bf1%3A0x88e5e8407da87d4!2s7%2F138-148+Walker+St%2C+Dandenong+VIC+3175%2C+Australia!5e0!3m2!1sen!2sin!4v1464417273591" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
                </div>
             </div>
          </div>
          </section>
          <Footer/>
      </>
  )
};

export default Contact;
