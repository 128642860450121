import React,  { Component, Fragment } from 'react';
import Header from '../../../../common/Header';
import Footer from '../../../../common/Footer';
import TaxServices from '../../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class TransferPricing extends Component {
    render() {
        return (
           <Fragment>
            <Header />
               {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Transfer Pricing</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li><Link to="/services/tax-services">Tax Services</Link></li> <li>Transfer Pricing</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section coGbg">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center flex-sm-wrap flex-md-nowrap">
            <div className="p-2  paratext2 ">
              <div className="title-section style3 left">
                <h1 className="title">Transfer Pricing Accounting Services</h1>
              </div> 
              <p>Our professionals work to help de-risk transfer pricing processes. We can do so for every business. Over the years, with our assistance, we have provided senior-level, independent consultation. Besides, you can also get the analysis on Transfer Pricing matters for clients. Our experience lies in working across a broad range of industries. By transfer pricing, we usually refer to the setting of a price for services and goods that are lying between related legal entities.</p>
              <p>We will ensure that every business can de-risk transfer pricing matters with assistance. We hold the specialty of doing that with filing audit reports,  effective documentation, litigation support, and all other aspects. The pricing is optimized for the international transactions between two associated enterprises.  We understand how there are strict rules for transfer pricing. That said, with us, you can get the Quality Assured Transfer price services.</p>    
            </div>
            <div className="p-2 flex-grow-1 sideImage"><div><img src={process.env.PUBLIC_URL + "../../assets/images/services/Transfer-Pricing.jpg"} alt="Compliance Tax" /></div></div>
          </div>
          <div className="paratext2 page-pd-5rem">
            <h3 className="title">The Range of Services That We Can Offer : </h3>
            <p>The best part of our transfer pricing professionals is that we have the technical expertise and knowledge that can help assist in all the aspects of transfer pricing matters. Over the years, we have worked for clients across a broad range of industries. That said, we come with the specialty in the development of the ideal transfer pricing policies. Besides, we can also ensure the management of transfer pricing accounting. That said, we can help maximize the potential for increased results related to after-tax income. We can also help to ensure effective transfer pricing policies that can also hold innovative approaches.</p>
            <p>We can also assist the small scale to large scale enterprises that will also ensure the delivery of ideal transfer pricing solutions that are favorable for all types of industries.</p>
            <h3 className="title">What Makes Us The Most Experienced?</h3>
            <p>We are an experienced global team who can always work with Exceptional client services. You can also get practical solutions that can work for every stage of transfer pricing. This can also ensure working with Single point contact for all your essential needs. With that, you can get the transfer Pricing Service. </p>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default TransferPricing