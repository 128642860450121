import React,  { Component, Fragment } from 'react';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import TaxServices from '../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";
class OnlineAccounting extends Component {

    render() {
        return (
           <Fragment>
            <Header />
                      {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Online Accounting</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li>Online Accounting</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-section style3 left">
                <h1 className="title">Online Accounting</h1>
              </div>
            </div>
            <div className="col-12 paratext">
              <p>
                We can provide you with accountant services that will be good for the small and large businesses. We always focus on the enhancement of the efficiency of focusing more on productive activities. We are the high-end professionals who always utilize a latest accounting software and Management of the proper cash flow of the business. We always stick to sending the invoice to the company promptly. 
              </p><p> We can do our best to use the methodology to transition the complete online accounting preparation applications efficiently. We understand that often there is a mismatch between the employer contributions relating to the problems. That said, we will always maintain accuracy and report the problem instantly. So, you will never face any problem with our service.
              </p>
            </div>
          </div>
          <div className="row rowLine">
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">1</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content opened">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Management Accounts</h5>  
                        <div className="content">
                          <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>We can provide you with the accounting of the financial information and reports like the invoice, financial balance statement shared by the finance administration directly. Our team excels in the collection, analysis, dissemination, and interpretation of the information for managers of the organization. We can make your task easier by ensuring that the plans and managed adequately. We can still keep the detailed operating reports that can be prepared at the earliest on a timely basis. </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">2</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Bookkeeping</h5>  
                        <div className="content">
                        <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>We can give you the bookkeeping services with our staff that can care about the small and mid-sized business accounting. In our work, we also provide the valuable accounting suggestion whenever it is needed. The virtual bookkeeping services that we supply ensure that we have the complete record organized in one place.</p> 
                          <p>It will also help in the analysis of the performance of the organization. The financial statements that we hold have outstanding ideas for the growth of your business. The certified bookkeepers with us ensure the credit control and updated production of the customer statement. Preparation of the balances in time makes us stand as the best. </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">3</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Accounts Payable </h5>  
                        <div className="content">
                        <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>
                            We can provide you with the accounts payable services that can help in meeting the regulatory requirements. We can optimize the cost by timely payment of bills with the involvement of no interest and penalty. We can always properly optimize Working Capital Management. We can give you a discount with the avoidance of duplicate payment. The increased control with the top-level executive ensures that we stand out with our services. We can also provide you with the data online bookkeeping with the automated system for account management.
                          </p> 
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">4</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Accounts Receivable </h5>  
                        <div className="content">
                          <p>
                            We can provide you with the accounts receivable Management that will ensure better cash flow management for the business. We can also ensure a reduction in the bad debt by focusing on the company. We care about management efficiency while producing administrative costs. The minimized credit risk with the decreased overhead costs that you will get with us makes us unique. We can also save you time while reducing human error. Our professionals will increase the business outcome and will help you achieve the benefits.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">5</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Inventory Management</h5>  
                        <div className="content">
                          <p>
                            We have the proper knowledge of inventory management. In that regard, we can offer you online accounting services that will keep track of the goods across the business supply chain. We will optimize the entire spectrum from the order placement with the vendor to even the order delivery to the customer. We can make the complete journey and then allow you to get a concise report about the business staff. We will ensure that it is effective and less inclusive of the hassle. We can also manage it over multiple locations.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">6</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Financial Statements </h5>  
                        <div className="content">
                          <p>
                            The financial statement preparation for small businesses becomes a long-standing service. Depending on the client's need, we can always prepare the financial statement with accounting services that can keep the balance of the monthly, quarterly, and yearly expenses. We always utilize state-of-the-art technology in the concise reporting of the financial statements. We also have well-vetted professionals who can work with a helpful comparison and calculation for useful and practical business analysis.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">7</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Business Activity Statements</h5>  
                        <div className="content">
                          <p>
                            We can always prepare the comprehensive service for completion of the lodging of the statement. We can do everything electronically and can also avoid interest and penalties. You can rest assured about the arrangements that will be working for an extended period with us. We can ensure that the business activities statement that we prepare is insightful and will give you all the relevant information based on the business tax implications, GST, fringe benefits tax, equalization tax, and more than that.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">8</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Payroll Services </h5>  
                        <div className="content">
                          <p>
                            We always hold professional expertise with the online payroll services that will be working for small businesses. We can ensure your investment of time and strength in keeping the payroll management's pace for you. We always formulate ways to stick to state-of-the-art technology rather than operating with the time-consuming task. We can provide customized outsource payroll services that will be meeting with the client's requirements. Our expert teams always work to analyze the payroll software and suggest a cost-effective system working for your business.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">9</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Tax Return Preparation</h5>  
                        <div className="content">
                          <p>
                            In case you are looking for the best method for handling the tax with the preparatory services, it's worth considering our preparation services because we can work with a variety of businesses that clients need. You can rely on the support with us because we can work even with the tax return preparation. We always utilize the strategies that will help in keeping a record of the tax-related task. Implementation of all the requirements and keeping the business safe is our specialty.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">10</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Cash Flow Management</h5>  
                        <div className="content">
                          <p> We have the most straightforward way of maintaining cash flow management, where we can update the cash flow crucially and ensure that it will help make sound financial decisions. We always enhance the goal of usage of the fund. In this regard, we can keep the pace of speeding up collections while avoiding unnecessary and easy disbursement .overall, the cash flow noting and maintenance that we offer interest minimize time and involvement of the Management of the company into the routine, mundane process.</p><p> We have the proper identification of the competitors, and we define the objectives of financial goals. We can also include an effective implementation strategy for the achievement of goals. By now, you've realized how essential the Accounting and Bookkeeping services are related to your business. The bookkeeping and accounting services that we provide are good enough to keep track of all your records related to the income and expenses mainly. Accounting is also similar to bookkeeping, but in accounting, we have to interpret the financial records to make the strategic.</p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default OnlineAccounting