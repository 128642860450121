
import PropTypes from "prop-types";
import React from "react";
import clientData from "../data/our-clients.json";
import OurClientsSingle from "../components/pages/single/OurClientsSingle";

const OurClients = () => {
  return (
    <section className="flat-row section-client bg-section">
          <div className="container">
            <div className="row">       
              <div className="col-md-12">
                <div className="flat-client" data-item={5} data-nav="false" data-dots="false" data-auto="false">
          {clientData &&
            clientData.map((single, key) => {
              return (
                <OurClientsSingle
                  data={single}
                  key={key}
                  spaceBottomClass="mb-30"
                />
              );
            })}
            </div>{/* /.flat-client */}      
              </div>
            </div>
          </div>             
        </section>
  );
};

OurClients.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default OurClients;
