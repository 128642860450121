import React,  { Component, Fragment } from 'react';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import TaxServices from '../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";

class PayrollAccounting extends Component {
    render() {
        return (
           <Fragment>
            <Header />
                      {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Payroll Accounting Services</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li>Payroll Accounting Services</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-section style3 left">
                <h1 className="title">PAYROLL Accounting Services</h1>
              </div>
            </div>
            <div className="col-12 paratext">
              <p>
                We can provide you the Payroll Accounting that works as the function of calculating and distributing wages. Besides, we also specialize in analyzing salaries and withholdings to employees. We are also involved with certain agencies. We do it through different documents. In our work, we always involve timesheets, paychecks, and payroll ledger. We specialize in offering the Payroll Accounting that comes with the involvement of issuing reports to upper management.
              </p>
            </div>
          </div>
          <div className="row rowLine">
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">1</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content opened">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Processing of monthly payroll </h5>  
                        <div className="content">
                         <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>We cater for the processing of payroll. We do so on a weekly, monthly, fortnightly, or 4-weekly basis. You can also customize the frequency at any time within the same company dataset. You can access our support system that comes with the Employees &gt; Select Employee from listing &gt; Payment and select payment mode strategy.</p> 
                          <p>We can process payroll by ensuring that you gather all of the necessary information for each pay period. We do so with the involvement of the pay rate and the number of hours of duty by each employee. You will also require providing the employee's hourly rate. We can ensure the payroll process involves ‘net pay’ after adjusting the necessary taxes and other deductions. </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">2</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Issue of Payslips </h5>  
                        <div className="content">
                        <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>With us, you can get assistance in preparing the monthly payroll of your employees. Besides, you can also get the convenience of issuing payslips to your employees and organizing payroll payments. With us, you can also get support for the statutory deductions to the government authorities. </p> 
                          <p>For this method to work out, all you have to do is simply review the payroll for accuracy. Then, provide us with the necessary information for going ahead with the method. Where necessary, we will excel in providing you with technical assistance at all times. </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">3</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">TDS Management </h5>  
                        <div className="content">
                        <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>
                            We excel in the comprehensive management of the TDS. We do so, starting from overseeing the Investment Declaration Documents to the generation of Form 16 and Form 12BA. In our work, we always use our upgraded techniques for upgrading the periodic development of taxation matters. We understand that it is not just about deducting tax. </p>
                          <p>Rather, we also consider the TDS returns. We excel in understanding the complexity of extensive TDS Management. We are the leading staffing services company that always takes care in enhancing the performance of your organization. For the work, we leverage our experience in managing your talent and HR process. 
                          </p> 
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">4</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">PF, ESIC &amp; Other Taxes Management</h5>  
                        <div className="content">
                          <p>
                            In our work, we provide reliable services in “PF, ESIC &amp; Other Laws Management. We have a proper understanding of and provide complete outsourcing solutions. We specialize in our service in ESIC and PT advisory, PF, calculations, salary payment. We can also provide a structured outsourcing model for calculation, payment, return filing, etc. We understand that the tax law is time-consuming. That said, to solve the inconvenience, we provide a reliable and reputed Payroll Management system. 
                          </p>
                          <p> We also consider the Labour Law consultancy and Compliance Services that are aimed at providing a wide range of services. Some of the best services that we provide are Payroll Management, Payroll Compliances,  Labour Compliance Audit, PF, ESIC, Labour Laws, Establishment Compliance, Contract Labour Compliance, Industrial law, to name a few. </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">5</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Tax projections of employees </h5>  
                        <div className="content">
                          <p>
                            We have specialized Payroll projections allowing you to see employee’s costs. This cost is inclusive of what they are earning every month. We also involve insurance and tax. At all times, we can guarantee you a more informed decision about whether it will be a flexible idea to hire a new worker or not. We can also show ways of making some significant savings. </p>
                          <p> The Salary Projection Report that we provide gives the deepest insights of the Earnings and Deduction Pay Head values. At work, we always involve the Income Tax for selected Employees. Twelve months projections hold the capability of displaying the actual when the payrolls get already processed for any given month. 
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">6</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Handling of employee queries</h5>  
                        <div className="content">
                          <p> HR folks are expected to observe the pattern of queries coming up. That said, they are meant to reflect upon the background of queries. There is the involvement of encountering in open house interactions and feedback from the line managers in the procedure. With us, you will support the payroll processing services that are structured for helping businesses outsource time-consuming payroll processes. </p>
                          <p> With us, you can rest assured about operational efficiency without compromising on accuracy. We also pay attention to the maintenance of timelines and compliance. We always utilize the technology backed with a proprietary payroll engine and a robust, secure infrastructure. So, you can rest assured about getting scalable solutions for processing employee payroll at a large scale. While doing so, we also ensure error-free data handling.</p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">7</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Full and Final Settlement </h5>  
                        <div className="content">
                          <p>
                            We can provide you with the Full and Final Settlement whenever an employee is leaving your organization. If you aren’t finding enough time to deal with such procedures, it is the best time to take our assistance. We ensure that the full and final settlement services will be aimed at handling process flow once an employee gets separated from the company. </p>
                          <p> In our work, we take the opportunity to coordinate with employees, the company HR department, payroll vendor, and all other sources that will prepare the defined process. We are the experts who can provide you solutions related to the preparing of full and final settlement of the employee.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </section>
       
             <Footer/>
           </Fragment>
        )
    }
}

export default PayrollAccounting