import React,  { Component, Fragment } from 'react';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import TaxServices from '../../../../assets/images/parallax/TaxServices.jpg';
import {Link} from "react-router-dom";
class SpecialistServices extends Component {

    render() {
        return (
           <Fragment>
            <Header />
                      {/* Page title */}
        <div className="page-title parallax" style={{ backgroundImage: `url(${TaxServices})` }}>
          <div className="section-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12"> 
                <div className="page-title-heading">
                  <h1 className="title">Specialist Services</h1>
                </div>{/* /.page-title-captions */}  
                <div className="breadcrumbs">
                  <ul>
                    <li className="home"><i className="fa fa-home" /><Link onClick={() => {window.location.href="/"}}>Home</Link></li>
                    <li><Link onClick={() => {window.location.href="/"}}>Services</Link></li>
                    <li>Specialist Services</li>
                  </ul>                   
                </div>{/* /.breadcrumbs */} 
              </div>{/* /.col-md-12 */}  
            </div>{/* /.row */}  
          </div>{/* /.container */}                      
        </div>{/* /.page-title */} 
        <section className="flat-row section-iconbox padding2 bg-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-section style3 left">
                <h1 className="title">Specialist Services</h1>
              </div>
            </div>
            <div className="col-12 paratext">
              <p>
                Several scenarios usually call for business to get the attainment of the business
                objectives. Whatever the situation is, we can always consider removing the stressful process
                while maximising the opportunity for you. These are the following specialist services that
                you will get with us.
              </p>
            </div>
          </div>
          <div className="row rowLine">
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">1</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content opened">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Acquisitions and mergers</h5>
                        <div className="content">
                          <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>Whether you are considering buying a business or just merging with
                            the business, we can always work with the experience and expertise
                            that you require. The services that we provide are our process for
                            identification of the potential equations and negotiating the
                            commercial elements of the transaction. It can help in forecasting
                            the future of the business. In work, we can give a review of the
                            goals and objectives, utilise due diligence services, and prepare
                            the financial forecast. We can assist with the person's negotiations
                            and advice on financing.</p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">2</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Company Secretary </h5>
                        <div className="content">
                        <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>
                            We understand that the company legislation always comes with the
                            requirement of the business to perform the administration. That
                            said, we can give the service that comes inclusive of the general
                            advice on Company Law, prepare the documentation related to the
                            minutes, and prepare the annual returns on your behalf.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">3</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Estate planning </h5>
                        <div className="content">
                        <img src={process.env.PUBLIC_URL + "../assets/images/services/payroll.jpg"} alt="Processing of monthly payroll" />
                          <p>
                            We can provide you with good planning skills and services to be a
                            part of our experienced team. We can guarantee no deviation from the
                            succession plan for the assets. With us, you can get the opportunity
                            of meeting to discuss objectives. We work for the register
                            documentation, Court approval for the grant of Administration, and
                            the estate distribution.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">4</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Farm accounting</h5>
                        <div className="content">
                          <p>
                            We can give the farm accounting services for specific businesses and
                            consider taxation issues and financial points. We can develop from
                            both the farming and accounting industries that can give up-to-date
                            and accurate advice. You can get the ownership structure
                            instructions in the planning, preparation of the financial
                            statements, and succession planning.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">5</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Human resources</h5>
                        <div className="content">
                          <p>
                            We understand the most valuable asset of your company becomes your
                            staff. In recognition of this, we always work to help you in the
                            most difficult point of your business. That said, we can give you
                            the HR Consulting Services that will stand out. We can always assist
                            in terms of the employment relations, health and safety recruitment
                            and selection, remuneration performance, management strategies, HR
                            team building facilities, and the change of Management.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">6</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Information Technology</h5>
                        <div className="content">
                          <p>
                            We live in the computerised age, and Technology plays a major role
                            in enhancing the variety of business processes. That said, we can
                            always effectively ensure our experienced and trained professionals
                            can discuss the best system to further your business. We do so by
                            utilising the recommended process for making the Computer System
                            Security stand out. We also consider training clients to become
                            self-sufficient and offer advice on electronic banking.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">7</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Rental property </h5>
                        <div className="content">
                          <p>
                            We can provide you with the required assistance in the variety of
                            areas that come inclusive of the practices like advising on
                            purchasing the property during the lease agreement,t advising on
                            funding, advising on ownership structure, guiding in terms of tax,
                            minimisation of tax, assisting in terms of the periodic reviews for
                            ensuring the returns are maximised.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">8</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Superannuation funds </h5>
                        <div className="content">
                          <p>
                            We can manage the superannuation fund by obtaining the trust deed
                            appointing trustees assigning the Trustee declaration. We favour
                            electing to become the regulated fund. We also work for obtaining
                            the tax file number as well as obtaining the Australian business
                            number. The professional advisor will also assist you in simplifying
                            the process.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">9</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Selling business </h5>
                        <div className="content">
                          <p>
                            Selling the business sometimes turns out to be quite difficult and
                            disheartening. that said, our professionals are well vetted to
                            assist you in obtaining the comparative results, establishing the
                            realistic prices, and taking the business to the market drafting the
                            contract.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">10</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Succession planning </h5>
                        <div className="content">
                          <p>
                            We can assist you in terms of ensuring long-term success. We
                            understand that sometimes it becomes critical to establish builders
                            and succession planning. That said, we can co-ordinate the
                            business's transition to success and give the best experience by
                            removing the Shoulders' stress.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">11</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Superannuation </h5>
                        <div className="content">
                          <p>
                            We understand that superannuation is the delicate area and so we can
                            give you the personalised planning that will be good enough for you.
                            We can assist you in a long term financial retirement Goals by
                            providing a service like established self-managed superannuation
                            funds, complete information for the insurance/ and superannuation
                            Commission, superannuation audit advice on the compliance matters,
                            advice on taxation benefits, as well as advice on the employee
                            litigations.
                          </p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex dropDownContent">
                <div className="p-2">
                  <div className="numbers">12</div>
                </div>
                <div className="p-2 flex-grow-1">
                  <div className="box-content">
                    <div className="d-flex align-items-center interFlex">
                      <div className="p-2">
                        <h5 className="box-title">Trusts </h5>
                        <div className="content">
                          <p>
                            The purpose of the trusts is to provide a valuable way of protecting
                            assets that you have accumulated for others' benefit. We have the
                            extensive experience that will be enabling us to offer the
                            specialist service and advice related to it. During the formation,
                            we ensure the statutory requirements being met. In the process, we
                            always prepare and execute the vital documentation, attend the
                            special requirements involved in reporting for the trust, and
                            complete the trust administration. Annual accounts Asset Management,
                            investment monitoring, as well as income distribution, makes us the
                            best.
                          </p>
                          <p>Contact us today and get our specialised services to ensure the trust
                            is formed and administered as effectively as possible. We can also
                            cover the legal requirements and can enter the fulfilment of the
                            purpose.</p>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="readmore" href="#"> <i className="fa fa-angle-down" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
             <Footer/>
           </Fragment>
        )
    }
}

export default SpecialistServices